export class VoteRatingRequest {

    constructor(
        public rating: number,
        public type: VotingType
    ) {}

    static fromJson(json: any): VoteRatingRequest {
        return new VoteRatingRequest(json.rating, json.type);
    }
}

export enum VotingType {
    Product,
    FutureZone
}
