import { Component, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Product } from 'src/app/model/Product';
import { CategoryId, CategoryResponse } from 'src/app/model/responses/CategoryResponse';
import { UserVote } from 'src/app/model/UserVote';
import { CategoryRestService } from 'src/app/services/category-rest.service';
import { ProductRestService } from 'src/app/services/product-rest.service';
import { UserRestService } from 'src/app/services/user-rest.service';
import { environment } from 'src/environments/environment';
import { RoutingService } from 'src/app/services/routing.service';
import { ProductOptions } from 'src/app/model/request/parameters/ProductOption';

@Component({
  selector: 'cewe-category-overview',
  templateUrl: './category-overview.component.html',
  styleUrls: ['./category-overview.component.scss']
})
export class CategoryOverviewComponent implements OnDestroy {

  category: CategoryResponse
  products: Product[] = []
  imageUrl: string;

  categoryTitle: string = "";

  votedProductsList: UserVote[]
  private cacheObserver = new Subject()

  private isFetchingProducts = false;

  constructor(
    private categoryService: CategoryRestService,
    private productService: ProductRestService,
    private userService: UserRestService,
    private routingService: RoutingService,
  ) { 
    this.routingService.routeChanged.pipe(takeUntil(this.cacheObserver)).subscribe(newLocation => {
      let id = newLocation.split("/")[2];
      if (id != undefined && id != "" && !this.isFetchingProducts) {
        this.setup(id)
      }
    })
    this.categoryService.cacheRenewed.pipe(takeUntil(this.cacheObserver)).subscribe(languageChanged => {
      if (languageChanged == true) {
          if (this.category != undefined && !this.isFetchingProducts) {
            this.productService.clearCache()
            this.setup(this.category.id)
          }
      }
    });
    this.userService.getUserVotedProducts().pipe(takeUntil(this.cacheObserver)).subscribe(votes => {
      this.votedProductsList = votes;
    })
  }

  ngOnDestroy(): void {
    this.cacheObserver.next(undefined);
    this.cacheObserver.unsubscribe()
  }

  private setup(id: string) {
    this.isFetchingProducts = true;
    let categoryId = new CategoryId(id)
    this.categoryService.getCategory(categoryId).subscribe(c => {
      if (c != undefined) {
        this.category = c
        this.categoryTitle = c.name
        this.products = []
        let productOptions = [ProductOptions.IMAGES, ProductOptions.VARIANT]
        this.category.productIds.forEach(productId => {
          this.productService.getProduct(productId, productOptions).subscribe(product => {
            this.products.push(product)
            this.products = this.products.sort((a, b) => a.customId - b.customId);
          })
        })
        this.products = this.products.sort((a, b) => a.customId - b.customId)
        this.imageUrl = `${environment.baseUrl}/images/${this.category.imageId}.jpg?size=W_700`;
      }
      this.isFetchingProducts = false;
    })
  }
}
