<section *ngIf="!this.multipleSelectable" [formGroup]="formGroup">
    <div *ngFor="let variant of this.variants" class="formContainer">
        <p>{{this.getVariantTitle(variant.id)}}</p>
        <div style="display: flex;">
            <input type="radio" [checked]="formGroup.value[variant.id] === true" (click)="radioButtonClicked(variant.id)">
        </div>
    </div>
</section>

<div *ngIf="this.multipleSelectable">
    <section class="example-section" [formGroup]="formGroup">
        <div *ngFor="let variant of this.variants" class="formContainerMultiple">
            <p>{{this.getVariantTitle(variant.id)}}</p>
            <mat-checkbox labelPosition="before" (change)="checkmarkClicked()" formControlName="{{variant.id}}"></mat-checkbox>
        </div>
    </section>
</div>