import { Injectable } from "@angular/core";
import { AbstractRestService } from "./abstract-rest.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import { CategoryId, CategoryResponse } from "../model/responses/CategoryResponse";
import {map} from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { TranslateDataService } from "./translate.service";
import { CategoryTypes, categoryTypeToAPIString } from "../global/CategoryType";

@Injectable({
    providedIn: 'root'
})
export class CategoryRestService extends AbstractRestService {

    private allCategories: CategoryResponse[]

    private lastCacheRenewal: number = 0;
    private currentlyFetching: boolean = false;
    public cacheRenewed: BehaviorSubject<boolean> = new BehaviorSubject(false);
    

    constructor(
        httpClient: HttpClient,
        private translateService: TranslateDataService,
    ) {
        super(httpClient);
        translateService.changeObservable.subscribe(changed => {
            if (changed) {
                this.renewCache();
            }
        })
    }

    protected get resourcePathSegment(): string {
        return "category"
    }

    public getAllCategories(): Observable<CategoryResponse[]> {
        if (this.categoriesInitialized()) {
            return of(this.allCategories)
        }
        return this.get('').pipe(map((categoryJson: CategoryResponse[]) => {
            let categories = categoryJson
            this.allCategories = categories
            return categories
        }))
    }

    public getCategory(categoryId: CategoryId): Observable<CategoryResponse> {
        if (this.categoriesInitialized()) {
            return of(this.allCategories.find(c => c.id == categoryId.value))
        }
        let response = this.getAllCategories().pipe(map(categories => {
            return categories.find(c => c.id == categoryId.value);
        }))
        return response
    }
    
    public getCategoriesByType(categoryType: CategoryTypes): Observable<CategoryResponse[]> {
        let typeString = categoryTypeToAPIString(categoryType);
        if (this.categoriesInitialized()) {
            return of(this.allCategories.filter(c => c.type == typeString))
        } else {
            let response = this.getAllCategories().pipe(map(categories => {
                this.allCategories = categories;
                return categories.filter(c => c.type == typeString);
            }))
            return response
        }
    }

    private renewCache() {
        if (!this.currentlyFetching) {
            this.currentlyFetching = true;
            this.allCategories = undefined;
            this.getAllCategories().subscribe(ignored => {
                this.currentlyFetching = false
                this.cacheCleared();
            })
        }
    }

    private cacheCleared() {
        let now = new Date().getTime();
        if (now - this.lastCacheRenewal > this.ONE_SECOND) {
            this.lastCacheRenewal = now;
            this.cacheRenewed.next(true);
        }
    }

    private categoriesInitialized(): boolean {
        return this.allCategories !== undefined
    }

    private ONE_SECOND = 1000
}