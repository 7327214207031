import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Variant } from "src/app/model/Variant";
import { ConfigService } from "src/app/services/config-rest.service";
import { ProductRestService } from "src/app/services/product-rest.service";
import { VoteService } from "src/app/services/vote.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { UserVote } from "src/app/model/UserVote";
import { VaraintId } from "src/app/model/VariantId";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, map } from "rxjs";
import { Product } from "src/app/model/Product";
import { VotingType } from "src/app/model/request/VoteRatingRequest";
import { Sorting } from "src/app/global/sorting";
import { has } from "lodash";

@Component({
    selector: 'cewe-product-variant',
    templateUrl: './product-variant.component.html',
    styleUrls: ['./product-variant.component.scss']
})
export class ProductVariantComponent implements OnInit, OnChanges {
    @Input() variants: Variant[];
    @Input() multipleSelectable: boolean;
    @Input() userVotes: UserVote[];
    @Input() product: Product;
    @Input() productIsLiked: boolean;

    private votingType: VotingType;

    formGroup = this._formBuilder.group({});

    constructor(
        private translationService: TranslateService,
        private voteService: VoteService,
        private _formBuilder: FormBuilder
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        let hashmap = this.generateFormHashmap()
        this.formGroup = this._formBuilder.group(hashmap);
    }
    
    ngOnInit() {
        let hashmap = this.generateFormHashmap()
        this.formGroup = this._formBuilder.group(hashmap);
        if (this.product != undefined) {
            this.votingType = Sorting.getVotingTypeOfProduct(this.product);
        }
    }

    getVariantTitle(variantId: string): string {
        let variant = this.variants.filter(v => v.id == variantId);
        if (variant.length == 1) {
            if (variant[0].translations.length != 0) {
                let filteredTitle = variant[0].translations.filter(translation =>  translation.language == this.translationService.currentLang);
                if (filteredTitle.length > 0) {
                    return filteredTitle[0].title;
                } else {
                    return variant[0].translations[0].title;
                }
            }
        } else {
            return "UNKNOWN ID: " + variantId
        }
    }

    alertFormValues(formGroup: FormGroup) {
        alert(JSON.stringify(formGroup.value, null, 2));
    }

    private generateFormHashmap(): {} {
        var result = new Map<String, String>();
        this.variants.forEach(variant => {
            result[variant.id] = false
        })
        if (this.userVotes != undefined && this.userVotes.length > 0) {
            let vote = this.userVotes.find(v => v.productId.value == this.product.productId)
            if (vote != undefined) {
                vote.variants.forEach(variantVote => {
                    result[variantVote.value] = true;
                })
            }
        }
        return result;
    }

    radioButtonClicked(variantId: string) {
        let wasAlreadyActive = this.formGroup.value[variantId] as boolean;
        let newMap = {};
        if (wasAlreadyActive) {
            this.formGroup.value[variantId] = false;
            this.voteVariants([]);
        } else {
            this.variants.forEach(variant => {
                newMap[variant.id] = variant.id == variantId;
            });
            this.formGroup = this._formBuilder.group(newMap);
            this.voteVariants([variantId])
        }
    }

    checkmarkClicked() {
        var variants = new Array<VaraintId>();
        this.variants.forEach(variant => {
            if (this.formGroup.value[variant.id] == true) {
                variants.push(new VaraintId(variant.id));
            }
        })

        this.voteService.voteForVariant(variants, this.product.productId, this.votingType, this.productIsLiked);
    }

    private voteVariants(variantIds: string[]) {
        let votedVariants = variantIds.map(v => new VaraintId(v))
        this.voteService.voteForVariant(votedVariants, this.product.productId, this.votingType, this.productIsLiked)
    }
}
