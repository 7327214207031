import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslatedTag } from 'src/app/model/Tag';
import { TagRestService } from 'src/app/services/tag-rest.service';
import { TagToTagIdMask } from '../tag_tagid';

const FUTURE_ZONE = "Future Zone";

@Component({
  selector: 'cewe-results-start',
  templateUrl: './results-start.component.html',
  styleUrls: ['./results-start.component.scss']
})
export class ResultsStartComponent implements OnInit {
  @Output() tags = new EventEmitter<String[]>();
  _tags: String[];

  constructor(
    private tagService: TagRestService
  ) { }

  ngOnInit(): void {
    this.tagService.getTags().subscribe(tags => {
      const sortedTags = this.sortTagIds(tags);
      this._tags = sortedTags
    });
  }

  start(): void {
    this.tags.emit(this._tags);
  }

  // This should never be done by the client but by the server. HOWEVER, we need to implement this feature 2 weeks before the deadline...
  private sortTagIds(tags: TranslatedTag[]): String[] {
    tags.forEach (tag => {
      TagToTagIdMask.set(tag.tagName, tag.tagId)
    });
    let resultingList =  [...TagToTagIdMask.values()] // Spreads values according to their position in the map into an array
    return resultingList
  }

}
