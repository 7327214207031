import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cewe-icon-and-text-button',
  templateUrl: './icon-and-text-button.component.html',
  styleUrls: ['./icon-and-text-button.component.scss']
})
export class IconAndTextButtonComponent implements OnInit {

  @Input() title: string = "No Title";
  @Input() iconSrc: string = "assets/graphics/global/icons/ico_blog.svg";
  @Input() hideLine: boolean = false;
  @Output() click = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  clicked() {
    this.click.emit(this.title)
  }

}
