<div class="wrapper">
    <div class="logo">
        <img class="cewe" src="assets/graphics/global/logo.svg">
        <img class="inno" src="assets/graphics/global/Innovation_Logo_2025.svg">
    </div>
    <div class="letsgo">
        <button (click)="start()">Los geht's<mat-icon class="arrow">keyboard_arrow_right</mat-icon></button>
    </div>
    <img class="wave" src="assets/graphics/results/wave.gif">
</div>
