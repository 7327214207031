import { Product } from "../model/Product"
import { VotingType } from "../model/request/VoteRatingRequest";
import { CategoryResponse } from "../model/responses/CategoryResponse"

export namespace Sorting{

    const FUTURE_ZONE = "Future Zone";

    export function sortCategoriesByPriority(categories: CategoryResponse[]): CategoryResponse[] {
        let categoriesWithPriorityNumber = categories.filter(c => c.priorityNumber != undefined)
        let rest = categories.filter(c => c.priorityNumber == undefined)
        let resultingList: CategoryResponse[] = []
        if (categoriesWithPriorityNumber.length > 0) {
            let sortedByNumber = categoriesWithPriorityNumber.sort((a, b) => b.priorityNumber - a.priorityNumber)
            sortedByNumber.forEach(sc => resultingList.push(sc))
        }
        if (rest.length > 0) {
            let sortedByName = rest.sort((a, b) => a.name < b.name ? -1 : 1)
            sortedByName.forEach(sc => resultingList.push(sc))
        }
        return resultingList
    }

    export function isFutureZone(product: Product): boolean {
        var isFZ = false;
        if (product.tags != undefined && product.tags.length > 0) {
            product.tags.forEach(tag => {
                if (tag.tagName == FUTURE_ZONE) {
                    isFZ = true;
                }
            })
        }
        return isFZ
    }
    
    export function getVotingTypeOfProduct(product: Product): VotingType {
        if (isFutureZone(product)) {
            return VotingType.FutureZone
        } else {
            return VotingType.Product
        }
    }
}
