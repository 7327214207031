import { BehaviorSubject, Observable } from "rxjs";
import { AbstractRestService } from "./abstract-rest.service";
import { HttpClient } from "@angular/common/http";
import { UserRestService } from "./user-rest.service";
import { map } from "lodash";
import { Injectable } from "@angular/core";
import { ParticipationRequest } from "../model/request/ParticipationRequest";
import { ParticipationResponse } from "../model/responses/ParticipationResponse";

@Injectable({
    providedIn: 'root'
})
export class ParticipationService extends AbstractRestService {
    protected get resourcePathSegment(): string {
        return "participation"
    }

    constructor(
        http: HttpClient
    ) {
        super(http);
    }

    participateOnProject(email: string, productId: string): Observable<ParticipationResponse> {
        let request = new ParticipationRequest(email)
        return this.post(productId, request)
    }

    getCurrentParticipationForProductOfUser(productId: string): Observable<ParticipationResponse> {
        return this.get(productId)
    }

    deleteParticipationForProduct(productId: string): Observable<undefined> {
        return this.delete(productId)
    }
    
}