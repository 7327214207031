import { Component, Input, OnInit } from '@angular/core';
import { VotingType } from 'src/app/model/request/VoteRatingRequest';
import { ParticipationService } from 'src/app/services/participation-rest.service';
import { VoteService } from 'src/app/services/vote.service';

@Component({
  selector: 'cewe-like-indicator',
  templateUrl: './like-indicator.component.html',
  styleUrls: ['./like-indicator.component.scss']
})
export class LikeIndicatorComponent implements OnInit {

  votesLeft: string = "0";
  participationsLeft: string = "0";


  constructor(
    private votingService: VoteService,
  ) {
    this.votingService.votesLeft.subscribe(map => {
      if (map != undefined) {
        this.votesLeft = this.numberToStringFormated(map[VotingType.Product])
        this.participationsLeft = this.numberToStringFormated(map[VotingType.FutureZone])
      }
    })
   }

  ngOnInit(): void {
  }

  private numberToStringFormated(n: number): string {
    if (n<10) {
      return "0" + n;
    } else {
      return "" + n
    }
  }

}
