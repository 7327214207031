import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateDataService } from '../../services/translate.service';
import { TranslateService } from '@ngx-translate/core';
import { AbstractTranslationService } from '../../services/abstract-translation.service';
import { EventDay } from 'src/app/model/EventDay';
import { EventRestService } from 'src/app/services/event-rest.service';
import { UserRestService } from 'src/app/services/user-rest.service';
import { Agenda } from 'src/app/model/Agenda';
import { RoutingLinks } from 'src/app/app-routing.module';

@Component({
  selector: 'cewe-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent extends AbstractTranslationService implements OnInit {
  panelOpenState = false;
  eventDays: EventDay[];

  dateOfFirstDay: string = "Loading";
  dateOfSecondDay: string = "Loading";

  constructor(private router: Router,
    public translateService: TranslateDataService,
    public eventService: EventRestService,
    public userService: UserRestService,
    public translate: TranslateService,
    public cd: ChangeDetectorRef) {
    super(translate, translateService);
    this.translateService.changeObservable.subscribe(languageChanged => {
      if (languageChanged) {
          this.updateContent();
      }
    });
  }

  ngOnInit(): void {
    this.eventService.getAllEvents(this.translateService.language).subscribe((events) => {
      let sortedEvents = events.sort(function(a, b) {
        return a.startDate.getTime() - b.startDate.getTime();
      });

      let firstDay: Agenda[] = new Array<Agenda>();
      let secondDay: Agenda[] = new Array<Agenda>();
      
      sortedEvents.forEach((event) => {
        if(firstDay.length == 0) {
          firstDay.push(event);
        } else {
          if (event.startDate.getDay() > firstDay[firstDay.length - 1].startDate.getDay()) {
            secondDay.push(event);
          } else {
            firstDay.push(event);
          }
        }
      });

      this.eventDays = new Array<EventDay>();

      if (firstDay != undefined && firstDay.length > 0) {
        const firstEvent = firstDay[0].startDate;
        this.eventDays.push(new EventDay(firstEvent, firstDay));
        this.dateOfFirstDay = this.formatEventDate(firstEvent)
      }
      if (secondDay != undefined && secondDay.length > 0) {
        const firstEvent = secondDay[0].startDate;
        this.eventDays.push(new EventDay(firstEvent, secondDay))
        this.dateOfSecondDay = this.formatEventDate(firstEvent)
      }
      this.cd.markForCheck();
    })
  }

  openDashboard() {
    this.router.navigate([RoutingLinks.Dashboard]);
  }

  private updateContent() {
    this.ngOnInit()
  }

  private formatEventDate(date: Date): string {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let dayString = day.toString()
    if (day < 10) {
       dayString = "0" + day;
    }
    let monthString = month.toString();
    if (month < 10) {
      monthString = "0" + month;
    }
    return dayString + "." + monthString + "." + year;
  }
}
