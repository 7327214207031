import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cewe-text-button',
  templateUrl: './text-button.component.html',
  styleUrls: ['./text-button.component.scss']
})
export class TextButtonComponent implements OnInit {

  @Input() title: string = "No Title";
  @Input() hideLine: boolean = false;
  @Output() click = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  clicked() {
    this.click.emit(this.title)
  }
}
