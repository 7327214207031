import {Component, OnInit} from '@angular/core';
import {ProductRestService} from '../../services/product-rest.service';
import {Product} from '../../model/Product';
import {UserRestService} from '../../services/user-rest.service';
import {User} from '../../model/User';
import {catchError} from 'rxjs/operators';
import {BehaviorSubject, throwError} from 'rxjs';
import {VERSION} from '../../../environments/version';
import {VoteService} from '../../services/vote.service';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {TranslateDataService} from 'src/app/services/translate.service';
import {TranslateService} from '@ngx-translate/core';
import {TagRestService} from '../../services/tag-rest.service';
import {AbstractTranslationService} from '../../services/abstract-translation.service';
import { ProductOptions } from 'src/app/model/request/parameters/ProductOption';
import { UserVote } from 'src/app/model/UserVote';
import { RoutingLinks } from 'src/app/app-routing.module';

@Component({
    selector: 'cewe-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent extends AbstractTranslationService implements OnInit {
    content: Product[] = [];
    filteredContent: Product[] = [];

    votedProductsList: UserVote[] = [];
    userFeedbackMap = new Map<string, string>();

    search = '';
    showSearch = false;
    showLike = false;

    loading = true;
    tenantId: number;
    starsButton: boolean;

    constructor(private readonly productService: ProductRestService,
                public readonly userService: UserRestService,
                public voteService: VoteService,
                private tagService: TagRestService,
                private router: Router,
                public translate: TranslateService,
                public translateService: TranslateDataService
    ) {
        super(translate, translateService);
        this.starsButton = environment.starsButton;
        this.tenantId = environment.tenantId;

        this.userService.getUserCommentedProducts().subscribe(userFeedbackMap => {
            this.userFeedbackMap = userFeedbackMap;
        });

        this.translateService.changeObservable.subscribe(languageChanged => {
            if (languageChanged == true) {
                this.productService.clearCache();
                this.updateContent();
            }
        });
    }

    ngOnInit() {
        this.voteService.votedProducts.subscribe(products => {
            this.votedProductsList = products
        })
        this.updateContent()
    }

    updateSearch(event: string) {
        this.search = event;
        this.filterSearchContent();
    }

    /*/ only shows liked products /*/
    toggleGlobalLikeButton() {
        window.scrollTo(0, 0);
        this.showLike = !this.showLike
        this.filterSearchContent()
    }

    getFilteredProduct(productId: string): Product | undefined {
        var result = undefined;
        this.filteredContent.forEach(content => {
            if (content.productId == productId) {
                result = content;
            }
        })
        return result;
    }

    /** Filters the content by product name, tag and custom id. */
    filterSearchContent() {
        let prefilteredContent = this.prefilterContent()
        if (prefilteredContent.length == 0) {
            this.filteredContent = []
            return;
        }
        this.filteredContent = prefilteredContent.filter(product => {
            const lowerCaseSearch = this.search.toLowerCase();
            const searchId = "#" + product.customId;
            const tags = product.tags;
            const productName = product.name.toLowerCase();
            if (searchId.includes(lowerCaseSearch)) {
                return true;
            } else if(productName.includes(lowerCaseSearch)) {
                return true;
            } else {
                if (tags != undefined) {
                    let tagHit = false
                    let index = 0;
                    while (index < tags.length && !tagHit) {
                        var tag = tags[index];
                        let tagname = tag.tagName.toLowerCase()
                        tagHit = tagname === lowerCaseSearch;
                        index = index + 1
                    }
                    return tagHit;
                }
            }
            return false;
        });
    }

    private prefilterContent(): Product[] {
        if (this.showLike) {
            return this.content.filter(product => this.productIsLiked(product.productId))
        } else if (this.search == ""){
            return [];
        } else {
            return this.content
        }
    }

    private updateContent(): void {
        this.loading = true;
        const productOptions = new Array<ProductOptions>(ProductOptions.IMAGES, ProductOptions.VARIANT);
        this.productService.getAllProducts(productOptions).subscribe((products) => {
            this.content = products;
            this.filterSearchContent();
            this.loading = false;
        });
    }

    openDashboard() {
        this.tagService.searchTag('');
        this.router.navigate([RoutingLinks.Dashboard]);
    }


    private filterForTagName(products: Product[], tagName: string): Product[] {
        return products.filter((product) => {
            let tags = product.tags;
            if (tags == undefined || tags.length == 0) {
                return false
            }
            let match = tags.filter((tag) => tag.tagName == tagName);
            if (match.length > 0) {
                return true;
            } else {
                return false
            }
        })
    }

    productIsLiked(productId: String): boolean {
        let isLiked = false;
        let filtered = this.votedProductsList.filter(vote => vote.productId.value == productId && vote.rating == 1);
        isLiked = filtered != undefined && filtered.length != 0;
        return isLiked;
    }
}
