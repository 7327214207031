<!-- background -->
<div class="header-background">
    <div class="home_search_container">
        <img src="assets/graphics/global/icons/Ico_home.svg" (click)="sendToStart()">
        <mat-icon (click)="sendToOverview()">search</mat-icon>
    </div>
    <p *ngIf="title != undefined && title != ''" (click)="sendToStart()" [ngClass]="title.length > 16 ? 'title larger_title' : 'title'">{{title}}</p>
    <img *ngIf="title == undefined || title == ''" (click)="sendToStart()"  src="assets/graphics/global/logo.svg" class="title">
    <div class="translation_toggle">
        <cewe-translation-toggle></cewe-translation-toggle>
    </div>
</div>
<!-- spacer -->
<div class="header-spacer"></div>
