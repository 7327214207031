<cewe-header [hideSearch]="true"></cewe-header>
<!-- overview container -->
<div class="overview-background" id="tile">
    <!-- search bar -->
    <div class="search_bar">
        <div class="search_container">
            <label>{{'misc.searchLabel' | translate}}</label>
            <cewe-text-input [placeholder]="'overview.search' | translate" (input)="updateSearch($event)"></cewe-text-input>
        </div>
        <div class="likes_container">
            <label>{{'misc.favourites' | translate}}</label>
            <div *ngIf="showLike" class="like_button liked" (click)="toggleGlobalLikeButton()">
                <img src="assets/graphics/global/icons/ico_filter_favorites.svg" alt="Likes">
            </div>
            <div *ngIf="!showLike" class="like_button not_liked" (click)="toggleGlobalLikeButton()">
                <img src="assets/graphics/global/icons/ico_filter_favorites.svg" alt="Likes">
            </div>
        </div>
    </div>

    <!-- tiles -->
    <div *ngFor="let content of filteredContent | sortgrid: 'id'">
        <cewe-product class="tile" [product]="content" [userVotes]="this.votedProductsList"></cewe-product>
    </div>

    <!-- loading screen -->
    <div class="tile-noResult" *ngIf="filteredContent.length == 0 && loading">
        <p>{{'overview.loading' | translate}}</p>
        <img id="loadingSpinner" class="rotation" src="assets/graphics/global/loading_circle.svg" alt="Loading">
    </div>
    <!-- search no results -->
    <div class="tile-noResult" *ngIf="filteredContent.length == 0 && (!loading && !showLike) && search != ''">
        <p>{{'overview.emptySearch' | translate}}</p>
    </div>
    <!-- like filter without results -->
    <div class="tile-noResult" *ngIf="filteredContent.length == 0 && (!loading && showLike)">
        <p>{{'overview.emptyFavorites' | translate}}</p>
    </div>

    <cewe-like-indicator></cewe-like-indicator>
    <div class="footer-spacer"></div>
    <cewe-footer-spacer></cewe-footer-spacer>
    <cewe-footer></cewe-footer>
</div>
