// This is a last ditch effort to implement a feature. This should be in the database but we can't risk to destroy it 2 weeks before the start...
// This is being used since Dec. 2023.
export const TagToTagnameMap = new Map<string, string>([
    ['HPS', 'Desktop Software'],
    ['Future Zone', 'Future Zone'],
    ['MPS', 'Mobile Apps'],
    ['COPS', 'CEWE Online Photo Service'],
    ['CMP', 'CEWE myPhotos'],
    ['DH', 'Digital Heritage'],
    ['MAIC', 'MAIC'],
    ['OSF', 'Onsite Finishing'],
    ['PHB', 'Photo Book'],
    ['WA', 'Wall Art'],
    ['CA', 'Photo Calendars'],
    ['GC', 'Greeting Cards'],
    ['PH', 'Photos & Prints'],
    ['ADV', 'Advent Calendars'],
    ['DG', 'Design'],
    ['PC/PG', 'Phone Cases & Photo Gifts'],
    ['UPE', 'Unpacking Expierence']
])