import { Injectable } from "@angular/core";
import { AbstractRestService } from "./abstract-rest.service";
import { map, Observable, of } from "rxjs";
import { TenantConfigResponse } from "../model/responses/TenantConfigResponse";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class TenantConfigService extends AbstractRestService {

    tenantConfig: TenantConfigResponse | undefined;

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    protected get resourcePathSegment(): string {
        return "configs"
    }

    public getTenantConfig(tenantId: string): Observable<TenantConfigResponse> {
        if (this.tenantConfig != undefined) {
            return of(this.tenantConfig)
        } else {
            return this.get(tenantId).pipe(map(config => {
                const c = config as unknown as TenantConfigResponse
                this.tenantConfig = c
                return config
            }))
        }
    }
    
}
