import {Component, OnInit} from '@angular/core';
import {ConfigService} from './services/config-rest.service';
import {AuthGuardService} from './services/auth-guard.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';

@Component({
    selector: 'cewe-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    ngOnInit() {
    }

    constructor(private configService: ConfigService,
                private authService: AuthGuardService,
                private title: Title) {
        this.configService.getTenantConfig().subscribe();
        this.authService.checkForValidUserId();
        if (environment.tenantId === 1) {
            title.setTitle('Innovation Days 2025');
        } else {
            title.setTitle('SHIP IT DAYS 2024');
        }
    }
}
