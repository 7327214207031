import {Component, OnInit} from '@angular/core';
import {TranslateDataService} from '../../../services/translate.service';
import {TranslateService} from '@ngx-translate/core';
import {AbstractTranslationService} from '../../../services/abstract-translation.service';
import { Router } from '@angular/router';
import { UserRestService } from 'src/app/services/user-rest.service';
import { RoutingLinks } from 'src/app/app-routing.module';
import { CategoryStrings } from 'src/app/global/CategoryType';
import { TenantConfigService } from 'src/app/services/tenant-rest.service';

@Component({
    selector: 'cewe-innodays-dashboard',
    templateUrl: './innodays-dashboard.component.html',
    styleUrls: ['./innodays-dashboard.component.scss']
})
export class InnodaysDashboardSimpleComponent extends AbstractTranslationService implements OnInit {

    amountOfVotes: number;
    amountOfParticipations: number;

    constructor(
        public translateService: TranslateDataService,
        public translate: TranslateService,
        public router: Router,
        private tenantService: TenantConfigService,
        private userService: UserRestService,
    ) {
        super(translate, translateService);
        this.userService.getUser().subscribe(user => {
            this.tenantService.getTenantConfig(user.tenant).subscribe(config => {
                this.amountOfVotes = config.numberOfVotes;
                this.amountOfParticipations = config.numberOfParticipations;
            })
        })
    }
    ngOnInit(): void {}

    sendToProducts() {
        this.router.navigate([RoutingLinks.SuperCategory, CategoryStrings.Products])
    }
    sendToSoftware() {
        this.router.navigate([RoutingLinks.SuperCategory, CategoryStrings.Software])
    }
    sendToOSF() {
        this.router.navigate([RoutingLinks.SuperCategory, CategoryStrings.OSF])
    }
    sendToFutureZone() {
        this.router.navigate([RoutingLinks.SuperCategory, CategoryStrings.FutureZone])
    }
}
