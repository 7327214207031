import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class RoutingService {

    private previousLocation = "";

    constructor(
        private router: Router,
        private location: Location
    ) {
        router.events.subscribe(val => {
            let currentLocation = this.location.path();
            if (currentLocation != this.previousLocation) {
                this.previousLocation = currentLocation;
                this.routeChanged.next(currentLocation);
            }
        })
    }
    public routeChanged: BehaviorSubject<string> = new BehaviorSubject("")
}