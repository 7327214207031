<div class="participation_container">
    <div class="action_buttons">
        <h3>#{{product.customId}} - {{product.name}}</h3>
        <mat-icon (click)="close()">close</mat-icon>
    </div>
    <p>{{'participate.interest' | translate}}</p>
    <p>{{'participate.hereIsMyEmail' | translate}}</p>
    <div class="email_input_container">
        <cewe-text-input (input)="updateEmail($event)" [errorMessage]="errorMessage" [value]="currentEmailInput" [placeholder]="'Email'"></cewe-text-input>
    </div>
    <br>
    <div style="display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-around; width: 100%;">
        <button [disabled]="currentEmailInput === ''"  class="participation_button" (click)="deleteEmail()">
            {{'misc.delete' | translate}}
        </button>
        <button class="participation_button" [disabled]="emailMissing || emailInvalid || currentEmailInput === ''" (click)="sendEmail()">
            {{'feedback.send' | translate}}
        </button>
    </div>
</div>
