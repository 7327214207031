<cewe-header [title]="'FAQ'"></cewe-header>
<div class="paragraph">
    <h3 style="text-decoration: underline; font-weight: bold;">{{'faq.infoForIOS' | translate}}</h3>
    <p>{{'faq.infoForIOSDescription' | translate}}</p>
</div>
<div class="paragraph">
    <h3>{{'faq.voting' | translate}}</h3>
    <p>{{'faq.votingDescription' | translate}}</p>
</div>
<div class="paragraph">
    <h3>{{'faq.search' | translate}}</h3>
    <p>{{'faq.searchDescription' | translate}}</p>
</div>
<div class="paragraph">
    <h3>{{'faq.feedback' | translate}}</h3>
    <p>{{'faq.feedbackDescription' | translate}}</p>
</div>
<div style="height: 50px; width: 100svw;"></div>