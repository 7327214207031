import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';
import {UserRestService} from '../../services/user-rest.service';
import {User} from '../../model/User';
import {Router} from '@angular/router';
import {AuthGuardService} from '../../services/auth-guard.service';
import {UserRequest} from 'src/app/model/request/UserRequest';
import {TranslateDataService} from 'src/app/services/translate.service';
import {ConfigService} from '../../services/config-rest.service';
import {AbstractTranslationService} from '../../services/abstract-translation.service';
import {TranslateService} from '@ngx-translate/core';
import { RoutingLinks } from 'src/app/app-routing.module';
import { VoteService } from 'src/app/services/vote.service';
import { catchError, Subject, takeUntil, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'cewe-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends AbstractTranslationService implements OnInit, OnDestroy {
    password = '';
    wrongPassword = false;
    falseUserId: boolean;
    assets: string;
    username: string;
    authType: string;
    hideLogin : boolean = true;

    private cacheObserver: Subject<undefined> = new Subject()

    constructor(
        private readonly userService: UserRestService,
        private router: Router,
        private authGuard: AuthGuardService,
        public translate: TranslateService,
        public translateService: TranslateDataService,
        private configService: ConfigService,
        private votingService: VoteService
    ) {
        super(translate, translateService);

        this.configService.observable.pipe(takeUntil(this.cacheObserver)).subscribe((configMap) => {
            this.authType = this.configService.authType;
            if (this.userService.isValidUserId() || this.authType === 'NONE') {
                this.router.navigate([RoutingLinks.Agenda]).then(r => ['']);
            }
            if (!this.authType) {
                this.router.navigate([RoutingLinks.Dashboard]).then(r => ['']);
            }
        });
    }

    ngOnDestroy(): void {
        this.cacheObserver.next(undefined);
        this.cacheObserver.unsubscribe();
    }

    ngOnInit() {
        this.falseUserId = environment.falseUserId;
    }


    myFunction() {
      this.hideLogin = !this.hideLogin;
    }

    login() {
        this.wrongPassword = false;
        this.falseUserId = false;
        this.userService.postUser(new UserRequest(this.translateService.language, environment.tenantId, this.password)).pipe(takeUntil(this.cacheObserver)).subscribe({
            next: (user: User) => {
                localStorage.setItem(environment.localStorageName, user.userId);
                this.authGuard.loggedIn = true;
                this.votingService.getRemainingLikes().pipe(takeUntil(this.cacheObserver), tap(ignored => {}), catchError((error: HttpErrorResponse) => {
                    if (error.status > 400) {
                        console.error("Cannot receive voting data: Status: " + error.status + " | Message: " + error.message);
                        this.router.navigate([RoutingLinks.Dashboard]);
                        return undefined;
                    }
                })).subscribe(ignored => {
                    this.router.navigate([RoutingLinks.Dashboard]);
                });
            }, 
            error: () => this.wrongPassword = true,
        });
    }
}
