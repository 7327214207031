export class User {
    constructor(public userId: string,
                public votesLeft: number,
                public participationVotesLeft: number,
                public language: string,
                public tenant: string) {
    }

    static fromJson(json: any): User {
        return new User(json.userId, json.votesLeft, json.participationVotesLeft, json.language, json.tenant);
    }
}
