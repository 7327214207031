import { VaraintId } from "../VariantId";
import { VotingType } from "./VoteRatingRequest";

export class VariantVoteRequest {
    productId: String;
    rating: number;
    type: VotingType;
    variantVotes: VaraintId[];

    constructor(productId: String, rating: number, type: VotingType, variantVotes: VaraintId[]) {
        this.productId = productId;
        this.rating = rating;
        this.type = type;
        this.variantVotes = variantVotes;
    }

    static fromJson(json: any): VariantVoteRequest {
        return new VariantVoteRequest(json.productId, json.rating, json.type, json.variantVotes);
    }
}