<!-- tile -->
<div class="tile">
    <!-- img / slideshow -->
    <ng-container *appLazyLoad>
        <div class="tile-img" *ngIf="product.images && product.images.length != 0">
            <carousel class="imageShow" [images]="imageUrls" [cellWidth]="'100%'" [autoplay]="false"
                [arrows]="this.imageUrls && this.imageUrls.length > 1" [pauseOnHover]="true" [loop]="true"
                [transitionTimingFunction]="'ease-in'" [transitionDuration]="300" [lightDOM]="true" [objectFit]="'scale-down'">
            </carousel>
        </div>
    </ng-container>

    <!-- tile header -->
    <div  [ngClass]="product.images != undefined ? 'tile-header' : 'tile-header-alt'">
        <!-- title and feedback button with img -->
        <h2 [ngClass]="product.images != undefined && product.images.length !== 0 ? 'tile-title' : 'tile-title-noImage'">{{product.name}}</h2>
    </div>
        
        <!-- tile body -->
    <div class="tile-body">
        <ngx-md class="tile-info" *ngIf="product.description !== ''" [data]="product.description"></ngx-md>
        <br>
        <cewe-product-variant 
            *ngIf="product.variants != undefined" 
            [variants]="product.variants" 
            [multipleSelectable]="product.multipleVariantsSelectable" 
            [userVotes]="userVotes"
            [product]="this.product"
            [productIsLiked]="this.isLiked"></cewe-product-variant>
        <p class="tile-info">{{product.responsible}}</p>

        <div class="tags_and_id_box">
            <!-- tile tags -->
            <div class="tile-tags-container" *ngIf="product.tags != undefined && product.tags.length > 0">
                <div class="tile-tag" *ngFor="let productTag of product.tags" (click)="toggleTag(productTag.tagName)">
                    {{productTag.tagName}}
                </div>
            </div>
            <div class="custom_id">
                <a>#{{product.customId}}</a>
            </div>
        </div>
        
    </div>
        
    <div class="feedbackRow">
        <button (click)="openFeedbackModal()">
            <img src="assets/graphics/global/icons/ico_blog.svg" alt="">
        </button>
        <div class="border_line"></div>
        <!-- likebutton -->
        <button [ngClass]="isLiked ? 'liked' : ''"  (click)="likeProduct()">
            <div *ngIf="isLiked">
                <img *ngIf="isFutureZone()" src="assets/graphics/global/icons/ico_star_filled_white.svg">
                <img *ngIf="!isFutureZone()" src="assets/graphics/global/icons/ico_heart_filled.svg">
            </div>
            <div *ngIf="!isLiked">
                <img *ngIf="isFutureZone()" src="assets/graphics/global/icons/ico_star_white.svg">
                <img *ngIf="!isFutureZone()" src="assets/graphics/global/icons/ico_heart.svg">
            </div>
        </button>
        <div *ngIf="this.isFutureZone()" class="border_line"></div>
        <button *ngIf="this.isFutureZone()" (click)="participate()">
            <img src="assets/graphics/global/icons/ico_newsletter.svg" alt="">
        </button>
    </div>
</div>