<div class="like_indicator_background">
    <div class="indicator_box">
        <img src="assets/graphics/global/icons/ico_heart_filled.svg">
        <label>{{votesLeft}}</label>
    </div>
    <div class="show_border"></div>
    <div class="indicator_box">
        <img src="assets/graphics/global/icons/ico_star_filled_white.svg">
        <label>{{participationsLeft}}</label> 
    </div>
</div>