import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {TranslateDataService} from 'src/app/services/translate.service';
import {ConfigService} from '../../services/config-rest.service';
import { UserRestService } from 'src/app/services/user-rest.service';

const GERMAN: string = "de";
const ENGLISH: string = "en";

@Component({
    selector: 'cewe-translation-toggle',
    templateUrl: './translation-toggle.component.html',
    styleUrls: ['./translation-toggle.component.scss']
})
export class TranslationToggleComponent implements OnInit {
    languages: any[] = [];

    currentLanguage: String = "";

    constructor(
        public translateService: TranslateDataService,
        private userService: UserRestService,
        private configService: ConfigService,
        private eRef: ElementRef
    ) {}

    ngOnInit() {
        this.configService.observable.subscribe((client) => {
            this.languages = this.configService.supportedLanguages;
            this.userService.getUser().subscribe(user => {
                this.currentLanguage = user.language
            })
        });
    }

    switchLanguage() {
        if (this.currentLanguage == GERMAN) {
            this.translateService.setLanguage(ENGLISH, true)
        } else {
            this.translateService.setLanguage(GERMAN, true)
        }
    }
}
