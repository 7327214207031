<cewe-header></cewe-header>

<div class="text_container">
    <p>{{'innovations.preambel' | translate}}</p>
    <p [translate]="'innovations.votingExplanation'" [translateParams]="{amountOfVotes: amountOfVotes, amountOfParticipations: amountOfParticipations}" ></p>
    <p>{{'innovations.participationDescription' | translate}}</p>
</div>

<div class="button_container">
    <cewe-icon-and-text-button [title]="'innovations.products' | translate" [iconSrc]="'assets/graphics/global/icons/ico_photobook_pink.svg'" (click)="sendToProducts()"></cewe-icon-and-text-button>
    <cewe-icon-and-text-button [title]="'innovations.software' | translate" [iconSrc]="'assets/graphics/global/icons/ico_devices_pink.svg'" (click)="sendToSoftware()"></cewe-icon-and-text-button>
    <cewe-icon-and-text-button [title]="'innovations.osf' | translate" [iconSrc]="'assets/graphics/global/icons/ico_photostation_pink.svg'" (click)="sendToOSF()"></cewe-icon-and-text-button>
    <cewe-icon-and-text-button [title]="'innovations.futureZone' | translate" [iconSrc]="'assets/graphics/global/icons/ico_future_pink.svg'" [hideLine]="true" (click)="sendToFutureZone()"></cewe-icon-and-text-button>
</div>

<cewe-footer-spacer></cewe-footer-spacer>
<cewe-footer></cewe-footer>
