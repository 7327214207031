export const CategoryStrings = {
    Products: "products",
    Software: "software",
    OSF: "osf",
    FutureZone: "futurezone"
} as const;

export enum CategoryTypes {
    PRODUCTS,
    SOFTWARE,
    OSF,
    FUTURE_ZONE
}

export function stringToCategoryType(stringType: string): CategoryTypes | undefined{
    switch(stringType) {
        case CategoryStrings.Products: return CategoryTypes.PRODUCTS;
        case CategoryStrings.Software: return CategoryTypes.SOFTWARE;
        case CategoryStrings.OSF: return CategoryTypes.OSF;
        case CategoryStrings.FutureZone: return CategoryTypes.FUTURE_ZONE;
        default: return CategoryTypes.PRODUCTS;
    }
}

export function categoryTypeToAPIString(type: CategoryTypes): string {
    switch(type) {
        case CategoryTypes.PRODUCTS: return "PRODUCT_MARKETING";
        case CategoryTypes.SOFTWARE: return "SOFTWARE";
        case CategoryTypes.OSF: return "OSF";
        case CategoryTypes.FUTURE_ZONE: return "FUTURE_ZONE";
    }
}