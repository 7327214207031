import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RoutingLinks } from 'src/app/app-routing.module';
import { AbstractTranslationService } from 'src/app/services/abstract-translation.service';
import { TranslateDataService } from 'src/app/services/translate.service';

@Component({
  selector: 'cewe-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent extends AbstractTranslationService implements OnInit {

  constructor(
    public translateService: TranslateDataService,
    public translate: TranslateService,
    private router: Router
  ) { 
    super(translate, translateService)
  }

  ngOnInit(): void {
    /*Safari has a cookie/localstorage policy that is too strict which causes it to delete or userId way too early. No easy fix is known. Therefore we just
    warn the user that they should consider using another browser. We can't hinder them however.*/
    if (this.getBrowserName() == "Safari") {
      this.translate.get("misc.wrongBrowser").subscribe(message => alert(message));
    }
  }

  sendToDashboard() {
    this.router.navigate([RoutingLinks.Dashboard])
  }


  sendToAgenda() {
    this.router.navigate([RoutingLinks.Agenda])
  }

  sendToFaq() {
    this.router.navigate([RoutingLinks.FAQ])
  }

  sendToMyphotos() {
    this.router.navigate([RoutingLinks.MyPhotos])
  }

  /**Used to determine which kind of browser is accesing the app. */
  private getBrowserName(): string {
    const agent = window.navigator.userAgent.toLowerCase();
    const browser =
      agent.indexOf('edge') > -1 ? 'Microsoft Edge'
        : agent.indexOf('edg') > -1 ? 'Chromium-based Edge'
        : agent.indexOf('opr') > -1 ? 'Opera'
        : agent.indexOf('chrome') > -1 ? 'Chrome'
        : agent.indexOf('trident') > -1 ? 'Internet Explorer'
        : agent.indexOf('firefox') > -1 ? 'Firefox'
        : agent.indexOf('safari') > -1 ? 'Safari'
        : 'other';

    return browser;
  }
}
