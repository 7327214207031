<cewe-header [title]="'myPhotos'"></cewe-header>
<div class="myphotos_container">
    <p>{{'agenda.cmpDescription' | translate}}</p>
    <img class="myphotos_image" src="assets/graphics/myphotos/cmp_skizze.png">
    <div>
        <button class="cmp-button"
            onClick="window.open('https://www.cewe-myphotos.com/open/sharedEvent/c86477c08f814b17a3f7427f4d30747a')">
            <mat-icon>wb_sunny</mat-icon>
            <p>{{'agenda.cmpButton1.2' | translate}}</p>
            <mat-icon class="open_icon">open_in_new</mat-icon>
        </button>
    
        <button class="cmp-button"
            onClick="window.open('https://www.cewe-myphotos.com/open/sharedEvent/4e75a70b621e47b2875d627c2c60a959')">
            <mat-icon>nights_stay</mat-icon>
            <p>{{'agenda.cmpButton2.2' | translate}}</p>
            <mat-icon class="open_icon">open_in_new</mat-icon>
        </button>
    </div>
</div>

