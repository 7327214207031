import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {AbstractRestService} from './abstract-rest.service';
import { TranslatedTag } from '../model/Tag';

@Injectable({
    providedIn: 'root'
})
export class TagRestService extends AbstractRestService {
    tag: string;
    behaviourSubject: BehaviorSubject<string>;
    observable: Observable<string>;

    constructor(httpClient: HttpClient) {
        super(httpClient);
        this.behaviourSubject = new BehaviorSubject<string>(undefined);
        this.observable = this.behaviourSubject.asObservable();
    }

    protected get resourcePathSegment(): string {
        return 'tags';
    }

    public getTags(): Observable<TranslatedTag[]> {
        return this.get('').pipe(map((tagsJson: any) => {
            return tagsJson.tags;
        }));
    }

    public searchTag(tag: string) {
        this.tag = tag;
        this.behaviourSubject.next(tag);
    }
}
