import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {AbstractRestService} from './abstract-rest.service';
import {User} from '../model/User';
import {UserRequest} from '../model/request/UserRequest';
import {UserUpdateRequest} from '../model/request/UserUpdateRequest';
import { environment } from 'src/environments/environment';
import { UserVote } from '../model/UserVote';
import { UserComments } from '../model/responses/UserComments';

@Injectable({
    providedIn: 'root'
})
export class UserRestService extends AbstractRestService {
    votedProducts = new Array<UserVote>;
    userCommtendProductsMap = new Map<string, string>();
    user: User | undefined;

    constructor(httpClient: HttpClient) {
        super(httpClient);
        this.getUserVotedProducts();
    }

    protected get resourcePathSegment(): string {
        return 'users';
    }

    public getUser(): Observable<User> {
        if (this.user != undefined) {
            return of(this.user)
        } else {
            return this.get('').pipe(map(userJson => {
                const user = User.fromJson(userJson);
                this.user = user;
                return user
            }));
        }
    }

    public postUser(userRequest: UserRequest): Observable<User> {
        return this.post('', userRequest).pipe(map(userJson => {
            const user = User.fromJson(userJson);
            this.user = user;
            return user;
        }));
    }

    public putUser(userUpdateRequest: UserUpdateRequest): Observable<User> {
        return this.put('', userUpdateRequest).pipe(map(userJson => {
            const user = User.fromJson(userJson);
            this.user = user;
            return user;
        }));
    }

    public getUserVotedProducts(): Observable<Array<UserVote>> {
        return this.get("votes").pipe(map((userVoteJson: any) => {
            if (userVoteJson != undefined) {
                this.votedProducts = userVoteJson.votes;
            } else {
                this.votedProducts = undefined;
            }
            return this.votedProducts;
        }));
    }

    public getUserCommentedProducts(): Observable<Map<string, string>> {
        return this.get('comments').pipe(map((userCommentJson: UserComments) => {
            if (userCommentJson !== null) {
                this.userCommtendProductsMap = userCommentJson.comments
                return this.userCommtendProductsMap
            }
            return this.userCommtendProductsMap;
        }));
    }

    public getUserId(): string {
        return environment.localStorageName in localStorage ? localStorage.getItem(environment.localStorageName) : null;
    }

    public isValidUserId(): boolean {
        return !(this.getUserId() === null || this.getUserId().length === 0 || this.getUserId() === '');
    }
}
