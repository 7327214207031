import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AbstractTranslationService } from 'src/app/services/abstract-translation.service';
import { TranslateDataService } from 'src/app/services/translate.service';

@Component({
  selector: 'cewe-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent extends AbstractTranslationService implements OnInit {

  constructor(
    public translate: TranslateService,
    public translateService: TranslateDataService,
  ) { 
    super(translate, translateService)
  }

  ngOnInit(): void {
  }

}
