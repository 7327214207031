import { Product } from "../Product"

export class CategoryResponse {
    constructor(
        public id: string,
        public name: string,
        public type: string,
        public language: string,
        public productIds: string[],
        public imageId?: string,
        public priorityNumber?: number,
    ) {}

    static fromJson(json: any): CategoryResponse {
        return new CategoryResponse(
            json.id,
            json.name,
            json.type,
            json.language,
            json.productIds,
            json.imageId,
            json.priorityNumber
        )
    }

}

export class CategoryId {
    constructor(
        public value: string
    ) {}
}