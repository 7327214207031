import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { random } from 'lodash';

@Component({
  selector: 'cewe-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent implements OnInit {

  textAreaId: string;

  @Input() label: string | undefined = undefined;
  @Input() placeholder: string = "";
  @Input() errorMessage: string | undefined = undefined;
  @Input() value: string = "";
  @Output() newInput: EventEmitter<String> = new EventEmitter()

  private currentValue: string;

  constructor() { 
    this.textAreaId = random(0, 100000000, false).toString() + "-" + new Date().getTime().toString() + "_text_area"
    this.currentValue = this.value;
  }

  ngOnInit(): void {
  }

  updateInput() {
    let textAreaValue = (document.getElementById(this.textAreaId) as HTMLTextAreaElement).value
    this.currentValue = textAreaValue;
    this.newInput.emit(this.currentValue)
  }

}
