import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, map} from 'rxjs';
import {VotesmessageComponent} from '../framework/votesmessage/votesmessage.component';
import {MatDialog} from '@angular/material/dialog';
import { Product } from '../model/Product';
import { HttpClient } from '@angular/common/http';
import { VariantVoteRequest } from '../model/request/VariantVoteRequest';
import { AbstractRestService } from './abstract-rest.service';
import { UserRestService } from './user-rest.service';
import { VaraintId } from '../model/VariantId';
import { VoteRatingRequest, VotingType } from '../model/request/VoteRatingRequest';
import { UserVote } from '../model/UserVote';
import { Sorting } from '../global/sorting';

@Injectable({
    providedIn: 'root'
})
export class VoteService extends AbstractRestService {
    protected get resourcePathSegment(): string {
        return "vote"
    }
    votesLeft: BehaviorSubject<Map<VotingType, number>>;
    votes: number;
    maxVotes: number;
    votedProducts: BehaviorSubject<UserVote[]>

    constructor(
        public dialog: MatDialog,
        private userService: UserRestService,
        http: HttpClient
    ) {
        super(http);
        this.votesLeft = new BehaviorSubject(undefined);
        this.votedProducts = new BehaviorSubject([])
        this.getRemainingLikes().subscribe( ignored => {});
        this.getVotedProducts()
        this.votes = 0;
        this.maxVotes = 0;
    }

    deleteVote(type: VotingType) {
        let map = this.votesLeft.value
        let currentAmount = map[type]
        if (currentAmount > 0) {
            map[type] = currentAmount - 1
        } else {
            map[type] = 0
        }
        this.votesLeft.next(map);
    }

    private addVote(type: VotingType) {
        let map = this.votesLeft.value
        let currentAmount = map[type]
        map[type] = currentAmount + 1
        this.votesLeft.next(map);
    }

    getRemainingLikes(): Observable<number> {
        return this.userService.getUser().pipe(map( user => {
            let futureZoneLikes = user.participationVotesLeft
            let productLike = user.votesLeft
            let map = new Map<VotingType, number>()
            map[VotingType.FutureZone] = futureZoneLikes
            map[VotingType.Product] = productLike
            this.votesLeft.next(map);
            this.votes = user.votesLeft
            return this.votes;
        }))
    }

    getVotedProducts() {
        return this.userService.getUserVotedProducts().subscribe(userVotes => {
            this.votedProducts.next(userVotes)
        })
    }

    openVoteMessageModal() {
        this.dialog.open(VotesmessageComponent, {
            width: '50%',
            panelClass: ['col-lg-4', 'col-12', 'votemessage-background']
        });
    }

    voteForProduct(product: Product): Observable<any> {
        let votingType = Sorting.getVotingTypeOfProduct(product)
        let request = new VoteRatingRequest(1, votingType);
        return this.put(product.productId + "/ratingOption", request).pipe(value => {
            this.deleteVote(votingType)
            return value
        })
    }

    unvoteForProduct(product: Product) {
        let votingType = Sorting.getVotingTypeOfProduct(product)
        let request = new VoteRatingRequest(0, votingType);
        this.put(product.productId + "/ratingOption", request).subscribe(_ => {
            this.addVote(votingType);
            this.getVotedProducts()
        })
    }

    voteForVariant(variantIds: VaraintId[], productId: String, votingType: VotingType, isLiked: boolean) {
        let request = new VariantVoteRequest(productId, isLiked ? 1 : 0, votingType, variantIds);
        this.put(productId + "/variant", request).subscribe(_ => {});
    }
}
