<cewe-header></cewe-header>

<div class="login-background" *ngIf="authType === 'PW' || authType === 'LDAP'">
    <input *ngIf="authType === 'LDAP'" type="text" (input)="username = $event.target.value" class="login-input-field"
        placeholder="{{'login.username' | translate}}" (keyup.enter)="login()">

    <div class="login-input-background">
        <input [type]="hideLogin ? 'password' : 'text'" (input)="password = $event.target.value"
            class="login-input-field" placeholder="{{'login.password' | translate}}" (keyup.enter)="login()">
        <mat-icon class="login-eye" *ngIf="hideLogin == false" (click)="myFunction()">visibility</mat-icon>
        <mat-icon class="login-eye" *ngIf="hideLogin == true" (click)="myFunction()">visibility_off</mat-icon>
    </div>

    <button mat-raised-button class="login" (click)="login()">Login</button>
    <p class="login-error">
        <span *ngIf="this.falseUserId">{{'login.invalidUser' | translate}}</span>
        <span *ngIf="this.wrongPassword">{{'login.invalidPassword' | translate}}</span>
    </p>
</div>