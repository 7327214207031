<div class="input_container">
    <input 
        id="{{inputId}}" 
        spellcheck="false"
        (input)="updateInput()"
        placeholder="{{placeholder}}" 
        autocomplete="off"
        [value]="value"
        >
    <label *ngIf="errorMessage != undefined" class="error_message">{{errorMessage}}</label>
</div>

