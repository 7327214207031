import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Product } from 'src/app/model/Product';
import { AbstractTranslationService } from 'src/app/services/abstract-translation.service';
import { TranslateDataService } from 'src/app/services/translate.service';
import { validate } from "email-validator";
import { ParticipationService } from 'src/app/services/participation-rest.service';

export interface ParticipateDialogData {
  product: Product
}

@Component({
  selector: 'cewe-participate',
  templateUrl: './participate.component.html',
  styleUrls: ['./participate.component.scss']
})
export class ParticipateComponent extends AbstractTranslationService implements OnInit {

  emailInvalid = false;
  emailMissing = false;
  currentEmailInput = "";

  errorMessage: string | undefined = undefined;

  product: Product;

  constructor(
    public translate: TranslateService,
    public translateService: TranslateDataService,
    private participationService: ParticipationService,
    public dialogRef: MatDialogRef<ParticipateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ParticipateDialogData,
  ) {
    super(translate, translateService)
    this.product = data.product;
   }

  ngOnInit(): void {
    this.participationService.getCurrentParticipationForProductOfUser(this.data.product.productId).subscribe(response => {
      if (response != undefined) {
        this.currentEmailInput = response.userEmail
      }
    })
  }

  updateEmail(event) {
    let input = (event.target.value as string).trimEnd()
    this.currentEmailInput = input
    this.checkEmailValidity(input)
  }

  sendEmail() {
    if (!this.emailInvalid && !this.emailMissing) {
      this.participationService.participateOnProject(this.currentEmailInput, this.data.product.productId)
        .subscribe(response => this.dialogRef.close() )
    }
  }

  deleteEmail() {
    this.currentEmailInput = '';
    // This fires every time even if there was no email in the beginning. TODO: Make this not a burden for the server.
    this.participationService.deleteParticipationForProduct(this.data.product.productId).subscribe(() => this.close())
  }

  private checkEmailValidity(email: string) {
    if (email.length == 0 || this.stringIsBlank(email)) {
      this.emailMissing = true;
      this.emailInvalid = false;
    } else if (!this.emailIsValid(email)) {
      this.emailMissing = false;
      this.emailInvalid = true;
    } else {
      this.emailMissing = false;
      this.emailInvalid = false
    }

    this.determineErrorMessage()
    
  }

  private determineErrorMessage() {
    if (this.emailMissing) {
      this.errorMessage = this.translate.instant("participate.missingEmail")
    } else if (this.emailInvalid) {
      this.errorMessage = this.translate.instant("participate.emailInvalid")
    } else {
      this.errorMessage = undefined;
    }
  }

  private stringIsBlank(v: string): boolean {
    let isBlank = true;
    var i = 0;
    while(isBlank && i < v.length) {
      if (v.charAt(i) != " ") {
        isBlank = false;
      }
      i = i + 1;
    }
    return isBlank;
  }

  
  private emailIsValid(email: string): boolean {
    return validate(email)
  }

  close() {
    this.dialogRef.close()
  }
}
