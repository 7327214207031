<div class="top_action_buttons">
    <h3>#{{feedbackProduct.customId}} - {{feedbackProduct.name}}</h3>
    <mat-icon (click)="onClose()">close</mat-icon>
</div>

<p class="description" >{{'feedback.feedbackDescription' | translate}}</p>

<cewe-text-area [value]="feedback" [placeholder]="'Feedback...'" (newInput)="initializeFeedback($event)"></cewe-text-area>
<p [translate]="'feedback.lettersLeft'" [translateParams]="{amount: feedbackPossibleCharacters}" class="letter_indicator"></p>

<div class="action_row">
    <button (click)="deleteFeedbackForProduct()" [disabled]="feedback.length == 0">{{'misc.delete' | translate}}</button>
    <button (click)="sendFeedback()" [disabled]="feedback.length == 0" >{{'feedback.send' | translate}}</button>
</div>

