<cewe-header></cewe-header>

<div class="category_container">
    <div *ngFor="let category of categories">
        <cewe-text-button [title]="category.name" (click)="sendToCategory(category)"></cewe-text-button>
    </div>
</div>

<cewe-footer-spacer></cewe-footer-spacer>
<cewe-footer></cewe-footer>
