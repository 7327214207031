import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { RoutingLinks } from 'src/app/app-routing.module';
import { CategoryTypes, stringToCategoryType } from 'src/app/global/CategoryType';
import { Sorting } from 'src/app/global/sorting';
import { CategoryResponse } from 'src/app/model/responses/CategoryResponse';
import { AbstractTranslationService } from 'src/app/services/abstract-translation.service';
import { CategoryRestService } from 'src/app/services/category-rest.service';
import { RoutingService } from 'src/app/services/routing.service';
import { TranslateDataService } from 'src/app/services/translate.service';

@Component({
  selector: 'cewe-product-marketing-overview',
  templateUrl: './super-category-overview.component.html',
  styleUrls: ['./super-category-overview.component.scss']
})
export class SuperCategoryOverviewComponent extends AbstractTranslationService implements OnInit, OnDestroy {

  categories: CategoryResponse[] = [];
  type: CategoryTypes;

  private cacheObserver = new Subject();

  constructor(
    private categoryService: CategoryRestService,
    public translateService: TranslateDataService,
    public translate: TranslateService,
    private routingService: RoutingService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
    super(translate, translateService)
    this.routingService.routeChanged.pipe(takeUntil(this.cacheObserver)).subscribe(newLocation => {
      let typeString = newLocation.split("/")[2];
      if (typeString != undefined && typeString != "") {
        let type = stringToCategoryType(typeString)
        if (type != undefined) {
          this.type = type
          this.setup()
        }
      }
    })
    categoryService.cacheRenewed.pipe(takeUntil(this.cacheObserver)).subscribe(cleared => {
      if (cleared) {
        this.setup();
      }
    })
  }
  
  ngOnInit(): void {
      this.setup()
  }

  ngOnDestroy(): void {
    this.cacheObserver.next(undefined);
    this.cacheObserver.unsubscribe();
  }

  private setup() {
    this.categoryService.getCategoriesByType(this.type).subscribe(categories => {
      if (categories != undefined) {
        if (categories.length == 1) {
          this.router.navigate([RoutingLinks.CategoryOverview, categories[0].id], {replaceUrl: true})
        } else {
          this.categories = Sorting.sortCategoriesByPriority(categories)
        }
      }
    })
  }

  sendToCategory(category: CategoryResponse) {
    this.router.navigate([RoutingLinks.CategoryOverview, category.id])
  }
}
