import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { RoutingLinks } from 'src/app/app-routing.module';
import { CategoryStrings } from 'src/app/global/CategoryType';
import { CategoryResponse } from 'src/app/model/responses/CategoryResponse';
import { AbstractTranslationService } from 'src/app/services/abstract-translation.service';
import { CategoryRestService } from 'src/app/services/category-rest.service';
import { TranslateDataService } from 'src/app/services/translate.service';

@Component({
  selector: 'cewe-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss', './futurezone-footer.component.scss']
})
export class FooterComponent extends AbstractTranslationService {

  categories: CategoryResponse[] = []
  productMarketingSource = "assets/graphics/categories/ProductMarketing_title_photos.png"
  @Input("isFutureZone") isFutureZone: Boolean = false 
  @Output("userClicked") userClicked: EventEmitter<string> = new EventEmitter();

  constructor(
    private categoryService: CategoryRestService,
    public translateService: TranslateDataService,
    public translate: TranslateService,
    private router: Router
  ) { 
    super(translate, translateService);
  }
  
  ngOnInit(): void {  }

  sendToProducts() {
    this.router.navigate([RoutingLinks.SuperCategory, CategoryStrings.Products])
  }
  sendToSoftware() {
    this.router.navigate([RoutingLinks.SuperCategory, CategoryStrings.Software])
  }
  sendToOSF() {
    this.router.navigate([RoutingLinks.SuperCategory, CategoryStrings.OSF])
  }
  sendToFutureZone() {
    this.router.navigate([RoutingLinks.SuperCategory, CategoryStrings.FutureZone])
  }
  
}
