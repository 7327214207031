import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AbstractTranslationService } from 'src/app/services/abstract-translation.service';
import { TranslateDataService } from 'src/app/services/translate.service';

@Component({
  selector: 'cewe-myphotos',
  templateUrl: './myphotos.component.html',
  styleUrls: ['./myphotos.component.scss']
})
export class MyphotosComponent extends AbstractTranslationService implements OnInit {

  constructor(
    public translate: TranslateService,
    public translateService: TranslateDataService,
  ) { 
    super(translate, translateService)
  }

  ngOnInit(): void {
  }

}
