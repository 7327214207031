import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {RatingOption} from 'src/app/model/RatingOption';
import {ConfigService} from 'src/app/services/config-rest.service';
import {environment} from 'src/environments/environment';
import {Product} from '../../../model/Product';
import {ProductRestService} from '../../../services/product-rest.service';
import {TagRestService} from '../../../services/tag-rest.service';
import {UserRestService} from '../../../services/user-rest.service';
import {VoteService} from '../../../services/vote.service';
import {FeedbackComponent} from '../feedback/feedback.component';
import { UserVote } from 'src/app/model/UserVote';
import { VotingStatusDialogComponent } from '../voting-status-dialog/voting-status-dialog.component';
import { ParticipateComponent } from '../participate/participate.component';
import { Sorting } from 'src/app/global/sorting';

export interface FeedbackDialogData {
    feedbackProduct: Product;
}

@Component({
    selector: 'cewe-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnChanges {
    @Input() product: Product;
    @Input() userVotes: UserVote[];
    
    isLiked: boolean;
    imageUrls: {}[] = [];
    ratingOptions: RatingOption[];
    votingEnabled: boolean;

    constructor(private readonly productService: ProductRestService,
                private dialog: MatDialog,
                private voteService: VoteService,
                private tagService: TagRestService,
                private userService: UserRestService,
                private configService: ConfigService,
    ) {
        this.configService.observable.subscribe(() => {
            this.ratingOptions = this.configService.ratingOptions;
            this.votingEnabled = this.configService.votingEnabled;
        });
    }

    ngOnInit() {
        this.determineIsLiked()
        if (this.product.images != undefined) {
            this.product.images.forEach(imageUrl => {
                if (imageUrl.imageId != undefined) {
                    this.imageUrls.push(
                        {
                            path: environment.baseUrl + '/images/' + imageUrl.imageId.value + '.jpg?uid=' + this.userService.getUserId() + '&size=W_700'
                        }
                    )
                }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        let newUserVotes = changes.userVotes;
        if (newUserVotes != undefined) {
            this.determineIsLiked();
        } 
    }

    openFeedbackModal() {
        this.dialog.open(FeedbackComponent, {
            width: '100%',
            height: '100%',
            maxWidth: '100 vw',
            data: {feedbackProduct: this.product},
        });
    }

    toggleTag(tag: string) {
        this.tagService.searchTag(tag);
    }

    likeProduct() {
        if (this.isLiked) {
            this.voteService.unvoteForProduct(this.product);
            this.isLiked = false;
        } else if (this.canVoteForProduct()){
            this.voteService.voteForProduct(this.product).subscribe({
                next: () => {
                    this.voteService.getVotedProducts()
                    this.isLiked = true;
                },
                error: value => {
                    if (value.status == 423 || value.status == 451) {
                        this.dialog.open(VotingStatusDialogComponent, {
                            data: {statusCode: value.status}
                        });
                    }
                }
            });
        }
    }

    isFutureZone(): boolean {
        return Sorting.isFutureZone(this.product)
    }

    participate() {
        this.dialog.open(ParticipateComponent, {
            width: '100%',
            height: '100%',
            maxWidth: '100 vw',
            data: {product: this.product},
        });
    }

    private canVoteForProduct() : Boolean {
        let votingMap = this.voteService.votesLeft.value
        let type = Sorting.getVotingTypeOfProduct(this.product)
        let currentVotes = votingMap[type]
        return currentVotes > 0
    }

    private determineIsLiked() {
        if (this.userVotes != undefined) {
            let vote = this.userVotes.find(p => p.productId.value == this.product.productId);
            this.isLiked = vote != undefined && vote.rating != 0;
        }
    }
}
