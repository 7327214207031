import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OverviewComponent} from './routing/overview/overview.component';
import {ResultsComponent} from './routing/results/results.component';
import {LoginComponent} from './routing/login/login.component';
import {AuthGuardService as AuthGuard} from './services/auth-guard.service';
import {FinalComponent} from './routing/final/final.component';
import {AgendaComponent} from './routing/agenda/agenda.component';
import { InnodaysDashboardSimpleComponent } from './routing/dashboard/innodays-dashboard/innodays-dashboard.component';
import { CategoryOverviewComponent } from './routing/category/category-overview/category-overview.component';
import { SuperCategoryOverviewComponent } from './routing/super-category/super-category-overview/super-category-overview.component';
import { StartComponent } from './routing/start/start.component';
import { FaqComponent } from './routing/faq/faq.component';
import { MyphotosComponent } from './routing/myphotos/myphotos.component';


export const RoutingLinks = {
    Overview: "overview",
    Results: "results",
    Login: "login",
    Dashboard: "dashboard",
    Agenda: "agenda",
    Final: "final",
    SuperCategory: "categories",
    SuperCategoryWithType: "categories/:type",
    CategoryOverview: "category",
    CategoryWithId: "category/:id",
    Start: "start",
    FAQ: "faq",
    MyPhotos: "myphotos"
} as const;

const routes: Routes = [
    {path: RoutingLinks.Overview, component: OverviewComponent, canActivate: [AuthGuard]},
    {path: RoutingLinks.Results, component: ResultsComponent, canActivate: [AuthGuard]},
    {path: RoutingLinks.Login, component: LoginComponent},
    {path: RoutingLinks.Dashboard, component: InnodaysDashboardSimpleComponent, canActivate: [AuthGuard]},
    {path: RoutingLinks.Agenda, component: AgendaComponent, canActivate: [AuthGuard]},
    {path: RoutingLinks.Final, component: FinalComponent},
    {path: RoutingLinks.CategoryWithId, component: CategoryOverviewComponent, canActivate: [AuthGuard]},
    {path: RoutingLinks.SuperCategoryWithType, component: SuperCategoryOverviewComponent, canActivate: [AuthGuard]},
    {path: RoutingLinks.FAQ, component: FaqComponent},
    {path: RoutingLinks.MyPhotos, component: MyphotosComponent, canActivate: [AuthGuard]},
    {path: RoutingLinks.Start, component: StartComponent},
    {path: '**', redirectTo: RoutingLinks.Start},
    {path: '', redirectTo: RoutingLinks.Start, pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true, relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
