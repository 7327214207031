import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingLinks } from 'src/app/app-routing.module';

@Component({
    selector: 'cewe-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    @Input() hideSearch: Boolean = false;
    @Input() title: string | undefined = undefined;

    constructor(
        private router: Router
    ) {}

    ngOnInit() {
    }

    sendToOverview() {
        this.router.navigate([RoutingLinks.Overview])
    }

    sendToStart() {
        this.router.navigate([RoutingLinks.Start])
    }
}
