<div class="textarea_container">
    <textarea 
        id="{{textAreaId}}" 
        spellcheck="false"
        (input)="updateInput()"
        placeholder="{{placeholder}}" 
        autocomplete="off"
        [value]="value"
    >
    </textarea>
</div>