<cewe-header></cewe-header>
<div class="container">
    <img class="bulb" src="assets/graphics/global/Innovation_Logo_2025.svg" alt="Innodays 2025 Logo">
    <br>
    <br>
    <div class="start_button_field">
        <cewe-icon-and-text-button [title]="'start.innovations' | translate" [iconSrc]="'assets/graphics/global/icons/ico_idea.svg'" (click)="sendToDashboard()"></cewe-icon-and-text-button>
        <cewe-icon-and-text-button [title]="'start.agenda' | translate" [iconSrc]="'assets/graphics/global/icons/ico_cal.svg'" (click)="sendToAgenda()"></cewe-icon-and-text-button>
        <cewe-icon-and-text-button [title]="'start.myphotos' | translate" [iconSrc]="'assets/graphics/global/icons/ico_myphotos.svg'" (click)="sendToMyphotos()"></cewe-icon-and-text-button>
        <cewe-icon-and-text-button [title]="'start.faq' | translate" [iconSrc]="'assets/graphics/global/icons/ico_help.svg'" [hideLine]="true" (click)="sendToFaq()"></cewe-icon-and-text-button>
    </div>
</div>
<cewe-footer-spacer></cewe-footer-spacer>
<cewe-footer></cewe-footer>
