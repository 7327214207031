import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { random } from 'lodash';

@Component({
  selector: 'cewe-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit {

  @Input() label: string | undefined = undefined;
  @Input() placeholder: string = "";
  @Input() errorMessage: string | undefined = undefined;
  @Input() value: string = "";
  @Output() input: EventEmitter<String> = new EventEmitter()

  inputId: string

  constructor() { 
    this.inputId = random(0, 100000000, false).toString() + "-" + new Date().getTime().toString() + "_input"
  }

  ngOnInit(): void {
  }

  updateInput() {
    let newInputValue = (document.getElementById(this.inputId) as HTMLInputElement).value
    this.input.emit(newInputValue)
  }


}
