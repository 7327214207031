import { Tag } from "./Tag";
import { Variant } from "./Variant";
import { VariantStatistics } from "./VariantStatistics";
import { Image } from "./Image";
import { CategoryId } from "./responses/CategoryResponse";

export class Product {
    constructor(
        public productId: string,
        public customId: number,
        public language: string,
        public name: string,
        public description: string,
        public responsible: string,
        public tags?: Tag[],
        public variants?: Variant[],
        public numberOfVotes?: number,
        public numberOfComments?: number,
        public avgRating?: number,
        public variantVotes?: VariantStatistics[],
        public multipleVariantsSelectable?: boolean,
        public ratingByOption?: [],
        public images?: Image[],
        public categoryId?: CategoryId
    ) {}

    static fromJson(json: any): Product {
        return new Product(
            json.productId,
            json.customId, 
            json.language, 
            json.name, 
            json.description, 
            json.responsible, 
            json.tags,
            json.variants, 
            json.numberOfVotes, 
            json.numberOfComments,
            json.avgRating, 
            json.variantVotes, 
            json.multipleVariantsSelectable, 
            json.ratingByOption, 
            json.images,
            json.categoryId
        );
    }
}

export class ProductId {
    constructor(public value: string) {}
}


export class TranslatedProductSupplement {
    constructor(
        public language: string,
        public name: string,
        public responsible: string,
        public description: string,
    ) {}
}